import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import { Col, Container, Row, Form, Card, CardHeader, CardBody, CardTitle, CardFooter, Input, FormGroup, Button } from "reactstrap";
import { Helmet } from 'react-helmet'

import { getUrlParameter } from 'utils/urls';
import { toggleStandardLoader } from 'store/functions/system/system';

import { setViewingUser } from 'store/functions/auth/auth'
import Logo from 'assets/img/Logo.png'

import api from 'api'

const Forgot = ({load}) => {

    const [err, setErr] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [redirect, setRedirect] = useState('');

  
	const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') document.getElementById("loginButton").click();
	}, [])


    const onRegister = useCallback(async () => {
        if(!email) return setErr(`Please enter your email`);
        if(!password) return setErr(`Please enter your password`);
        if(!passwordConfirm) return setErr(`Please confirm your password`);
        if(password !== passwordConfirm) return setErr(`Your passwords do not match`);

        toggleStandardLoader(true)
        const registered = await api.auth.register({ email, password, passwordConfirm })

        if(!registered.success) {
            toggleStandardLoader(false);
            if(registered.message) return setErr(registered.message[0])
            return setErr(`Could not register your account at this time, please try again.`)
        }

        const loggedIn = await api.auth.login({email, password})
        const user = await setViewingUser();
        toggleStandardLoader(false);
        setRedirect('/launchpad')


    }, [email, password, passwordConfirm])

    useEffect(() => {

        const _email = getUrlParameter('email');
        if(_email) setEmail(_email)
    }, [])


    if(redirect) return <Redirect to={redirect} />

    return (
        <div>
        <Helmet>
            <title>Login</title>
            <meta name="description" content="Login" />
        </Helmet>

        <div className="header  py-6 py-lg-5 mb-lg-5 pt-lg-3">
            <Container>
                <div className="header-body text-center mb-7">
                    <Row className="justify-content-center">
                        <Col className="px-5" lg="6" md="8" xl="5">

                            <img style={{width: 200}} alt="..."  src={Logo} />
                            <p className="mt-3 text-white font-weight-bold">Create An Account</p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>

        <Container className="mt--9 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">

                    <Form role="form">
                    
                        <Card className="bg-white border-0 mb-0" style={{boxShadow: 'rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px'}}>

                            <CardHeader>
                                <CardTitle className="mb-0">Register Your Account</CardTitle>
                            </CardHeader>

                            <CardBody className="">
                                    <FormGroup className="mb-3" >
                                    <label className="form-control-label">Email</label>
                                    <Input
                                        id="login-email-input"
                                        name="email"
                                        onKeyDown={_handleKeyDown}
                                        placeholder=""
                                        type="email"
                                        autoComplete="email"
                                        value={email	 || ''}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </FormGroup>

                                <FormGroup className="mt-4">
                                    <label className="form-control-label">Type Your Password</label>
                                    <Input
                                        name="password"
                                        onKeyDown={_handleKeyDown}
                                        placeholder=""
                                        type="password"
                                        autoComplete="current-password"
                                        value={password || ''}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </FormGroup>

                                <FormGroup className="mt-4">
                                    <label className="form-control-label">Confirm Your Password</label>
                                    <Input
                                        name="password"
                                        onKeyDown={_handleKeyDown}
                                        placeholder=""
                                        type="password"
                                        autoComplete="current-password"
                                        value={passwordConfirm || ''}
                                        onChange={(e) => setPasswordConfirm(e.target.value)}
                                    />
                                </FormGroup>
                            </CardBody>

                            {err ? (
                                <CardFooter>
                                    <p className="mb-0 text-danger font-weight-bold">{err}</p>
                                </CardFooter>
                            ) : null}

                            <CardFooter className="text-center">
                                <Button id="loginButton" onClick={onRegister} className="btn-block" color="success" type="button">
                                    Sign in
                                </Button>
                            </CardFooter>

                        </Card>

                    </Form>


                    <Row className="mt-3">

                        <Col xs="12" className="text-center">
                            <Link to={`/`} >
                                <small className='text-white'><i className="fas fa-arrow-left mr-2" /> Login</small>
                            </Link>
                        </Col>

                    </Row>

                </Col>
            </Row>
        </Container>

    </div>

    )
}

const mapStateToProps = state => {
    return {
		load: state.load,
    };
};

export default connect(mapStateToProps, '')(Forgot);

