import { connect } from 'react-redux'
import { Col, Row, Container, Card, CardBody, CardTitle, CardHeader, CardFooter } from 'reactstrap'

import { Helmet } from 'react-helmet';

import { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import api from 'api'

import Circle from 'components/markup/loading/Circle'

import moment from 'moment';

import img from './img.svg'

import { setHasSingleCase } from 'store/functions/dashboard/index'


const Home = ({ viewing_user }) => {

    const [err, setErr] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [matters, setMatters] = useState(null);

    const fetchData = useCallback(async () => {
        const data = await api.contacts.matters();
        if(!data.success) {
            document.body.classList.add('bg-info')
            return setErr(true)
        }

        if(data.data.length === 1) {
            setHasSingleCase(true)
            return setRedirect(`/${data.data[0].id}/dashboard`)
        }

        setMatters(data.data);
    }, [])

    useEffect(() => {
        fetchData()

        document.body.classList.add('bg-white')

        return () => {
            document.body.classList.remove('bg-white')
        }

    }, [fetchData])

    if(err) return (
        <p className='text-white py-6 text-center font-weight-bold px-5'>
            <i className="fas fa-info-circle mr-2" />
            An Unexpected Error Occurred, Please Refresh Your Page Or Contact Support If The Problem Persists
        </p>
    )

    if(redirect) return <Redirect to={redirect} />

    if(!matters) return <div className='py-6'><Circle /></div>

    return (
        <Container className='py-3'>

            <Helmet>
				<title>Launchpad</title>
				<meta name="description" content="Launchpad" />
			</Helmet>

            <h2 className='text-dark mt-5'>Hi{viewing_user.display_name ? ' ' + viewing_user.display_name : ''}, Welcome Back!</h2>

            <p className='text-sm mb-0'>Please Select The Case You Wish To View Below:</p> 

            {!matters.length ? (
                <div className='mt-5 border rounded p-4 bg-secondary'>
                    <p className='text-sm mb-0'><i className="fas fa-exclamation-triangle text-warning mr-2" /> It looks like no cases were found on your account, if you believe this to be an error please contact support.</p>
                </div>
            ) : (
                <Row className='mt-5'>
                    {matters.map(m => (
                        <Col md={4}>
                            <Link to={`/${m.id}/dashboard`}>
                                <Card className='z-depth-1 text-center' >

                                    <CardHeader>
                                        <CardTitle className="mb-0 text-dark">{m.name}</CardTitle>
                                    </CardHeader>

                                    <CardBody className='bg-secondary px-5'>
                                        <img src={img} alt="..." className='w-50' />
                                    </CardBody>

                                    <CardFooter>
                                        <p className='text-sm text-dark mb-0'>Updated: <b>{moment.unix(m.current_step_start).format('MM/DD/YYYY')}</b></p>
                                    </CardFooter>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            )}
          
        </Container>
    )
}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(Home);