
import Home from '../pages/home';
import Files from '../pages/files';
import Information from '../pages/information';
import Signatures from '../pages/signatures';
import Support from '../pages/support';
import Profile from '../pages/profile';

const routes = [

    {
        path: "/support",
        component: Support,
    },
  
    {
        path: "/signatures",
        component: Signatures,
    },
  
    {
        path: "/information",
        component: Information,
    },
  
    {
        path: "/files",
        component: Files,
    },
    {
        path: "/profile",
        component: Profile,
    },  
    {
        path: "/",
        component: Home,
    },
  
]

export default routes;