import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Col, Progress, Row, CardFooter } from 'reactstrap';
import { Helmet } from 'react-helmet';

import DragAndDrop from 'components/functional/uploads/DragAndDrop';

import api from 'api';
import moment from 'moment';

import Circle from 'components/markup/loading/Circle';

import { toast } from 'react-toastify';

import { onDownloadDocument } from 'utils/documents';
import formatText from 'utils/formatText';


const Files = ({ overview }) => {

    const percent = overview.totalDocuments ? parseFloat(((overview.completedDocuments / overview.totalDocuments) * 100).toFixed(1)) : 100

    const [selectedDocument, setSelectedDocument] = useState(overview.progressDocuments[0])
    const [documents, setDocuments] = useState(null)

    const fetchData = useCallback(async () => {
        setDocuments(null)
        if(!selectedDocument) return;
        if(!selectedDocument.matter_document_upload) return setDocuments([]);

        const data = await api.matter_document_uploads.findById(selectedDocument.matter_document_upload)
        if(data.data) setDocuments(data.data.documents.reverse())
    }, [selectedDocument])

    useEffect(() => {
        fetchData()
    }, [fetchData])


    return (
        <div>

            <Helmet>
                <title>Files</title>
                <meta name="description" content="Files" />
            </Helmet>

            <Card className='card-no-shadow'>
                <CardBody>
                    <p className='mb-0'><i className="fas fa-info-circle mr-2 text-info" /> This page allows you to upload documents to your case as well as monitor the approval status of the documents you have uploaded. </p>

                    <div className="progress-wrapper pt-3">
                        <div className="progress-info">
                            <div className="progress-label">
                                <span>Documents Approved</span>
                            </div>
                            <div className="progress-percentage">
                                <span>{percent}%</span>
                            </div>
                        </div>
                        <Progress max="100" value={percent < 2 ? 2 : percent} color="success" />
                    </div>

                </CardBody>
            </Card>

            {overview.progressDocuments.length ? (
                <Row>

                    <div className='col-auto archk-doc-col-left' style={{width: 200}}>

                        <ul className='pl-0 ml-0'>
                            {overview.progressDocuments.map((s, i) => {
                                const active = selectedDocument.matter_document === s.matter_document;
                                return (
                                    <li 
                                        key={i} 
                                        className={`cursor-pointer border  p-3 list-style-none ${active ? 'bg-success text-white' : ' bg-white'} ${i === 0 ? 'border-top-left-radius border-top-right-radius' : ''} ${i === overview.progressDocuments.length - 1 ? 'border-bottom-left-radius border-bottom-right-radius' : ''}`} 
                                        onClick={() => setSelectedDocument(s)}
                                    >
    
                                        <p className='text-sm mb-0 font-weight-bold'>
                                            {
                                                s.status === 'approved' ? <i className={`fas fa-check mr-2 ${active ? 'text-white' : ' text-success'}`} /> : 
                                                s.status === 'pending' ? <i className={`fas fa-info-circle mr-2 ${active ? 'text-white' : ' text-info'}`} /> :
                                                <i className={`fas fa-times mr-2 ${active ? 'text-white' : ' text-danger'}`} />
                                            }
                                            {s.matter_document_name}
                                        </p>

                                    </li>
                                )
                            })}
                        </ul>

                    </div>

                    <Col style={{width: 'calc(100% - 200px)'}} >

                    {selectedDocument.status === 'approved' ? (
                            <div className='alert alert-success '>This document category has been marked as approved, no further action needed!</div>
                        ) : ''}
                    
                        <h2 className='mb-3'>Uploading To: <b className='text-underline'>{selectedDocument.matter_document_name}</b></h2>

                        {selectedDocument.description ? (
                            <Card className='card-no-shadow mb-3'>
                                <CardBody>
                                    <p className='text-sm mb-0'>
                                        <i className="fas fa-info text-info mr-2" />{formatText(selectedDocument.description).stripHTML()}
                                    </p>
                                </CardBody>
                            </Card>
                        ) : ''}
                        
                     
                        <DragAndDrop
                            zone_id="archk-document-center2"
                            url={`/v1/documents`}
                            onUploadStarted={() => {
                                toast.info(`Document upload started.`)
                            }}
                            onSuccess={(file, request) => {
                                fetchData()

                                if(request && request.data && request.data.status === 'succeeded') {
                                    toast.success(`Document Upload Succeeded`)
                                } else if(request) {
                                    toast.error(`Something went wrong uploading a document, please try again.`)
                                }
                            }}
                            onError={(e) => {
                                toast.error('Document could not be uploaded, please try again or check the integrity of the file')
                            }}
                            resizePixels={1200}

                            extraData={{
                                matter: overview.matter._id,
                                matter_document: selectedDocument.matter_document,
                            }}
                        />

                        <Card className='card-no-shadow'>
                            <CardHeader>
                                <CardTitle className='mb-0'>Uploaded Documents</CardTitle>
                            </CardHeader>

                            {!documents ? <Circle /> : documents.length ? (
                                <div className='table-responsive'>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>Document</th>
                                                <th>Date Uploaded</th>
                                                <th></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {documents.map((d, i) => (
                                                <tr key={d._id}>
                                                    <td>{d.name}</td>
                                                    <td>{moment.unix(d.created_at).format('MM/DD/YYYY h:mm A')}</td>
                                                    <td className='text-right'>
                                                        <i onClick={() => onDownloadDocument(d._id)} className="fas fa-download cursor-pointer text-success" />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <CardBody>
                                    <p className='text-sm mb-0'>No documents have been uploaded to this category yet.</p>
                                </CardBody>
                            )}

                            {documents && documents.length ? (
                                <CardFooter>
                                    <p className='text-sm mb-0'><small>***Note that the above table shows the currently approved/pending documents in a category. This list may reflect differently than the exact list of documents you have uploaded.</small></p>
                                </CardFooter>
                            ) : ''}
                        </Card>

                        
                    </Col>

                </Row>
            ) : (
                <Card>
                    
                    <CardHeader>
                        <CardTitle className='mb-0'>Outstanding Documents</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className='text-sm mb-0 text-dark font-weight-bold'>
                            <i className="fas fa-check text-success mr-2" /> All Set! No Documents Need Your Attention
                        </p>
                    </CardBody>
                </Card>
            )}

        </div>
    )
}


const mapStateToProps = state => {
	return {
	    overview: state.dashboard.overview,
	};
};

export default connect(mapStateToProps, '')(Files);