import * as actionTypes from '../../actions';

const initialState = {
    has_single_case: false,
    err: false,
    overview: null,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_OVERVIEW:
            return { ...state, overview: action.payload }

        case actionTypes.SET_ERR:
            return { ...state, err: action.payload }
        
            case actionTypes.SET_HAS_SINGLE_CASE:
            return { ...state, has_single_case: action.payload }

        default: return state;

    }

}

export default reducer;
