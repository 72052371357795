import r from './request'

const api = {

    auth: {
        mfa: {
            send: (d)  => r('SERVER', 'get', `/v1/auth/mfa?_id=${d._id}&company=${d.company}&type=${d.type}`),   
            verify: (d)   => r('SERVER', 'post', `/v1/auth/mfa`, d),   
            set: (d)   => r('SERVER', 'post', `/v1/auth/mfa/set`, d),   
        },
        password: {
            update: (d)  => r('SERVER', 'post', `/v1/auth/password/update`, d),
            reset:  (d)  => r('SERVER', 'post', `/v1/auth/password/reset`, d),
        },
        securityCode: {
            create:   (d)   => r('SERVER', 'post', `/v1/auth/security_code/create`, d),
            validate:  (d)  => r('SERVER', 'post', `/v1/auth/security_code/validate`, d),
        },
        viewing_user: ()    => r('SERVER', 'get', `/v1/auth/viewing_user`),
        logout: ()    => r('SERVER', 'post', `/v1/auth/logout`),
        login: (d)    => r('SERVER', 'post', `/v1/auth/login`, d),
        register: (d)    => r('SERVER', 'post', `/v1/auth/register`, d),
    },
   
    load: {
        standard: ()  => r('SERVER', 'get', `/v1/load/standard`),   
    },
  
    dashboard: {
        overview: (i)  => r('SERVER', 'get', `/v1/dashboard/overview/${i}`),   
    },
    
    matter_document_uploads: {
        findById: (i)  => r('SERVER', 'get', `/v1/matter_document_uploads/${i}`),   
    },
   
    contacts: {
        support: (i)  => r('SERVER', 'get', `/v1/contacts/${i}/support`),   
        matters: (i)  => r('SERVER', 'get', `/v1/contacts/matters`),   
    },

}

export default api;
