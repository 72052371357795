/*
Documentation

load all styles, assets, here
App is wrapped in a store provider

*/

import "assets/scss/index.scss";
import 'react-toastify/dist/ReactToastify.css';

import App from './App'

import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

import store from 'store';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <App />
    </Provider>,
);