import keys from 'keys';
import Axios from 'axios'

import { toast } from 'react-toastify'

import fileDownloader from 'js-file-download';

export const onDownloadDocument = async (doc) => new Promise (async resolve => {

    try {
         const _id = doc && doc._id ? doc._id : doc
 
         // if document has a new icon next to it remove it from the page
         const badge = document.getElementById(`document-new-${_id}`)
         if(badge) badge.parentNode.removeChild(badge);
 
         const result = await Axios({
             method: 'get',
             url: keys.API_URL + `/v1/documents/${_id}/download`,
             responseType:'arraybuffer',
             headers: {
                 authorization: `Bearer ${keys.SYSTEM_API_KEY}`
             },
             withCredentials: true,
         })
 
         if(result.headers && result.headers['content-type'].includes('application/json')) {
             resolve(false);
             try {
                 var decodedString = String.fromCharCode.apply(null, new Uint8Array(result.data));
                 var body = JSON.parse(decodedString);
                 if(body.message && body.message.length) {
                     return toast.error(body.message[0] ? body.message[0] : `Something's Not Right, Please Try Again Later`)
                 }
             } catch(e) {
                 return toast.error(`Something went wrong downloading this document`)
             }
         }
 
         fileDownloader(result.data, result.headers.filename) 
         resolve(true);
     } catch(e) {
         resolve(false);
         return toast.error(`Something went wrong downloading this document`)
     }
 })