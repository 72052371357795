
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";

import LaunchPad from './LaunchPad';

const LaunchPadWrapper = (props) => {
    if(!props.viewing_user) return <Redirect to="/" />
    return <LaunchPad {...props} />
}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(LaunchPadWrapper);