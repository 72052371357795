/*
Documentation

this file handles password recovery by sending a hashed security code to a users email
it then verifies the code and lets them update their password

*/

import { useState, useCallback, useEffect } from "react";
import { Card, CardHeader, CardBody, CardTitle, CardFooter, Form, Input } from "reactstrap";

import Circle from 'components/markup/loading/Circle'

import api from 'api'


const ForgotConfirmEmail = ({onConfirmedEmail}) => {

    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [err, setErr] = useState('')

    //on enter simulate the form being submitted for better UI
	const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') document.getElementById("showEmailButton").click();
	}, [])

    const onSendCode = useCallback(async (e) => {

        if(!email) return setErr('You must add your email before Proceeding')

        //send a security code to the server
        //do not console.log this response in production as it holds
        //the code that was created and hashed
        setLoading(true)
        const createCode = await api.auth.securityCode.create({email})
        setLoading(false)

        if(createCode.success) {
            onConfirmedEmail(email)
        } else {
            setErr(createCode.message)
        }

    }, [onConfirmedEmail, email])

    const focusInput = useCallback(() => {
        const tryFocus = (tries = 0) => {
            if(tries > 20) return;
            const input = document.getElementById('forgot-email-input')
            if(input) {
                input.focus();
            } else {
                setTimeout(() => {
                    tryFocus(tries + 1) 
                }, 200)
            }
        }
        tryFocus();
    }, [])

    useEffect(() => {
        focusInput();
		window.addEventListener('focus', focusInput)
		return () => window.removeEventListener('focus', focusInput)
    }, [focusInput])

    return (
        <Form role="form" onSubmit={(e) => e.preventDefault()}>

            <Card className="bg-white border-0 mb-0 z-depth-1">

                <CardHeader>
                    <CardTitle className="mb-0">Forgot Password - Step 1 of 3</CardTitle>
                </CardHeader>

                <CardBody>

                    {loading ? (
                        <div className="py-6">
                            <Circle />
                        </div>
                    ) : (
                        <div>
                            <label className="form-control-label">Enter Your email</label>
                            <Input
                                id="forgot-email-input"
                                onKeyDown={_handleKeyDown}
                                placeholder="email"
                                type="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />

                        </div>
                    )}

                </CardBody>

                {err && (
                    <CardFooter>
                        <p className="text-sm text-danger font-weight-bold my-2 ">{err}</p>
                    </CardFooter>
                )}

                <CardFooter>
                    <button id="showEmailButton" onClick={onSendCode} className="btn btn-outline-success btn-block">
                        Send Recovery Code
                    </button>
                </CardFooter>

            </Card>

        </Form>
    )
	
}

export default ForgotConfirmEmail

