import { connect } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Helmet } from 'react-helmet';

import Circle from 'components/markup/loading/Circle';
import { useCallback, useEffect, useState } from 'react';

import api from 'api';

import formatText from 'utils/formatText';

const Signatures = ({ overview }) => {

    const [err, setErr] = useState(false)
    const [support, setSupport] = useState(null)

    const fetchData = useCallback(async () => {
        const data = await api.contacts.support(overview.matter._id);
        if(!data.success) return setErr(true)

        setSupport(data.data);
    }, [overview.matter._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) return <div className='alert alert-warning'>Could not load page, please refresh your browser or try again.</div>
    if(!support) return <div className='py-6'><Circle /> </div>

    return (
        <div>


            <Helmet>
                <title>Support</title>
                <meta name="description" content="Support" />
            </Helmet>

            <Card className='card-no-shadow'>
                <CardBody>
                    {support.users.length && support.phone ? (
                        <div>
                            <Row>
                                <Col md={6}>
                                    <h2>Points Of Contact</h2>
                                    {support.users.map((u, i) => (
                                        <p key={i} className='text-sm mb-0'>{u.display_name} {u.title ? ` - ${u.title} ` : ''} {u.email ? ` - ${u.email} ` : ''}</p>
                                    ))}
                                </Col>
                                <Col md={6}>
                                    <h2>My Dedicated Support Number</h2>
                                    <p className='text-sm mb-0'>{formatText(support.phone).phone()}</p>
                                </Col>
                            </Row>

                            <hr />

                            <p className='text-sm mb-0'>Here at {overview.division.display_name} we are committed to helping you every step of the way. Above you can view your dedicated support number as well as your point(s) of contact with us. </p>

                        </div>
                    ) : support.users.length ? (
                       <div>
                            <h2>Points Of Contact</h2>
                            {support.users.map((u, i) => (
                                <p key={i} className='text-sm mb-0'>{u.display_name} {u.title ? ` - ${u.title} ` : ''} {u.email ? ` - ${u.email} ` : ''}</p>
                            ))}
                            
                            <hr />
                            
                            <p className='text-sm mb-0'>Here at {overview.division.display_name} we are committed to helping you every step of the way. Above you can view your point(s) of contact with us. Feel free to contact us with any questions or concerns you may have. </p>

                       </div>
                    ) : support.phone ? (
                        <div>
                              <h2>My Dedicated Support Number</h2>
                              <p className='text-sm mb-0'>{formatText(support.phone).phone()}</p>

                            <hr />
                            
                            <p className='text-sm mb-0'>Here at {overview.division.display_name} we are committed to helping you every step of the way. Above you can view your dedicated support number. Feel free to contact us with any questions or concerns you may have. </p>
                        </div>
                    ) : (
                        <div>
                            <h2>Contact Us</h2>
                            <p className='text-sm mb-0'>Here at {overview.division.display_name} we are committed to helping you every step of the way. Don't hesitate to call or email us with any questions you may have. </p>
                        </div>
                    )}
                   

                   


                </CardBody>
              
            </Card>

        </div>
    )
}


const mapStateToProps = state => {
	return {
	    overview: state.dashboard.overview,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(Signatures);