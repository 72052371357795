import * as actionTypes from '../../actions';

const initialState = {
    standardLoader: false,
    loader: false,
    online: true,   
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.TOGGLE_STANDARD_LOADER:
            return { ...state, standardLoader: action.payload }

        case actionTypes.SET_ONLINE_STATUS:
            return { ...state, online: action.payload }

        case actionTypes.SET_SETTINGS:
            return { ...state, SETTINGS: action.payload }


        default: return state;

    }

}

export default reducer;
