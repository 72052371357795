/*
Documentation

This file holds the database calls and error logs for this system

*/

import Axios from 'axios';
import keys from '../keys'
import { toast } from 'react-toastify';

const isPrivilegeError = (result) => {
    return result.data.message
        && Array.isArray(result.data.message)
        && result.data.message.find(m => m.includes('is required to access this resource')) 
        ? true : false
}

const isTwillioOptOutError = (result) => {
    return result.data.message
        && result.data.message.code
        && result.data.message.code === 21610
        ? true : false
}

export const architeckCall = async (settings) => {
    return new Promise(async(resolve) => {

        const method = settings.method;
        const url = settings.url;
        let data = settings.data;
        const headers = settings.headers;

        if(!data) { data = {} }

        try {
            const result = await Axios({
                method,
                url: keys.API_URL + url,
                headers: {
                    ...headers,
                    authorization: `Bearer ${keys.SYSTEM_API_KEY}`
                },
                withCredentials: true,
                data,
            })


            resolve(result.data)

            if(!result.data.success) {

                if(isPrivilegeError(result)) {
                    return toast.error('Your account does not have access to this resource')
                } else if(isTwillioOptOutError(result)) {
                    return toast.error('This contact has opted out of text messages.')
                }  else {

                    // if this is a not authorized error we don't need to do anything
                    // this is most likely a phone logged in that did not redirect on a logout
                    if(result && result.message && result.message[0] === 'not authorized') return;

                    if(settings.shouldNotShowAndLogError !== true) {
                        logAndShowError(result)
                    }

                }

            }

        } catch(e) {

            resolve({success: false, message: 'architeckCall failed at url: ' + url + '. Error: ' + e.toString()})

            if(settings.shouldNotShowAndLogError !== true) {

                logAndShowError(e)

            }

        }

    })
}

const logAndShowError = (e) => {

    // don't log a failed internet connection
    if(e && e.message === 'Network Error') return;
    // don't log a computer giving a timeout error
    if(e && e.message && e.message.includes('timeout of')) return;

    console.log('API REQUEST FAILED ON URL: ')
    console.log(window.location.href)
    console.log(' ')
    console.log(e)


    log({
        url: window.location.href,
        file: '/src/database/js',
        code: 'REQUEST FAILURE',
        text: JSON.stringify(e)
    });

}

export const log = async (payload) => {

    //log must not be a standard call to prevent infinite loop if all requests fail
    //we will only fire off a console.log if we are in development and the log fails
    try {
        const log = await Axios({
            method: 'post',
            url: keys.API_URL + '/api/v1/logs/create',
            headers: {
                authorization: `Bearer ${keys.SYSTEM_API_KEY}`
            },
            withCredentials: true,
            data: {...payload}
        })

        if(!log.data.success && window.location.hostname === 'localhost') {
            console.log('log failed 1')
            console.log(log)
        }

    } catch(e) {

        if(window.location.hostname === 'localhost') {
            console.log('log failed 2')
            console.log(e)
        }

    }

}
