import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardFooter } from 'reactstrap'
import { connect } from 'react-redux'

import EventImg from './images/Event.svg'
import NoEventsImg from './images/NoEvents.svg'

import moment from 'moment'

const Home = ({ overview }) => {

    if(overview.nextEvent) {
        return (
            <Card className='card-no-shadow text-center'>
                <CardHeader>
                    <CardTitle className='mb-0'>Upcoming Event</CardTitle>
                </CardHeader>
    
                <CardBody className='bg-secondary'>
                    <img src={EventImg} alt="..." className='w-50' />
                </CardBody>
    
                <CardFooter>
                    <p className='mb-0 font-weight-bold'>{overview.nextEvent.name}</p>
                    <p className='text-sm mb-0 text-dark text-sm'>{moment.unix(overview.nextEvent.unix_start).format('MM/DD/YYYY h:mm A')}</p>
                </CardFooter>
            </Card>
        )
    } else {
        return (
            <Card className='card-no-shadow text-center'>
                <CardHeader>
                    <CardTitle className='mb-0'>Future Events</CardTitle>
                </CardHeader>
    
                <CardBody className='bg-secondary'>
                    <img src={NoEventsImg} alt="..." className='w-50' />
                </CardBody>
    
                <CardFooter>
                    <p className='mb-0 font-weight-bold'>No Upcoming Events Found</p>
                </CardFooter>
            </Card>
        )
    }

    
}


const mapStateToProps = state => {
	return {
	    overview: state.dashboard.overview,
	};
};

export default connect(mapStateToProps, '')(Home);