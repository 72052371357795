
import Home from '../pages/home';

const routes = [
    {
        path: "/",
        component: Home,
    },
  
]

export default routes;