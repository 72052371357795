
import { useEffect } from 'react';
import { connect } from 'react-redux';

import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import routes from "../layout/routes";




const Dashboard = ({ dashboard }) => {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0,0)
    }, [location.pathname])


    return (
        <div className="layout-auth" >

            <div>
                <Switch>
                    {routes.map((prop, key) => {

                        return (
                            <Route
                                exact path={`/launchpad${prop.path}`}
                                component={prop.component}
                                key={key}
                            />
                        )
                    })}
                    <Redirect from="*" to="/launchpad" />
                </Switch>
            </div>

            
        </div>
    );

}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Dashboard);