import { getUrlParameter } from 'utils/urls';

const redirectToApp = (load) => {
    const redirect = getUrlParameter('redirect');

    if(redirect && (redirect.includes(load.DOMAINS.APP) || redirect.includes(load.DOMAINS.ADMIN))) {
        window.location = redirect;
    } else {
        window.location = load.DOMAINS.APP;
    }
}

export default redirectToApp