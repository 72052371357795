import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Row, Col, FormGroup, Input } from 'reactstrap';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';

import formatText from 'utils/formatText'

import { useState, useCallback, useEffect } from 'react';

import api from 'api'

import { toast } from 'react-toastify'

const Signatures = ({ viewing_user }) => {

    const [err, setErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [hasMultipleCases, setHasMultipleCases] = useState(false)

    const fetchCases = useCallback(async () => {
        const data = await api.contacts.matters();
        if(data.data && data.data.length > 1) setHasMultipleCases(true)
    }, [])


    const onUpdatePassword = useCallback(async () => {
        setLoading(true)
        setErr(false);
        const updated = await api.auth.password.update({ password, passwordConfirm })
        setLoading(false)

        if(!updated.success) {
            if(updated.message[0]) return setErr(updated.message[0])
            return setErr(`Please try again, internal error.`)
        }

        toast.success(`Password updated successfully!`)
        setPassword('')
        setPasswordConfirm('')

    }, [password, passwordConfirm])

    useEffect(() => {
        fetchCases()
    }, [fetchCases])

    return (
        <div>

            <Helmet>
                <title>My Account</title>
                <meta name="description" content="My Account" />
            </Helmet>

            <Card className='card-no-shadow'>
                <CardHeader>
                    <CardTitle className='mb-0'>My Information</CardTitle>
                </CardHeader>

                <CardBody>
                    <Row>
                        <Col md={6}>
                            <p className='text-sm mb-0 font-weight-bold text-dark'>Name</p>
                            <p className='text-sm mb-0'>{viewing_user.display_name}</p>
                        
                            <p className='text-sm mb-0 font-weight-bold text-dark'>Primary Phone</p>
                            <p className='text-sm mb-0'>{viewing_user.phone ? formatText(viewing_user.phone).phone() : '-'}</p>

                            <p className='text-sm mb-0 font-weight-bold text-dark'>Primary Email</p>
                            <p className='text-sm mb-0'>{viewing_user.phone ? viewing_user.email : '-'}</p>
                        </Col>
                        <Col md={6}>
                            <p className='text-sm mb-0'><i className="fas fa-info text-info mr-2" /> In the effort to keep your account as secure as possible please contact us if you need to update your primary phone number or email address on file.</p>
                        </Col>
                    </Row>
                </CardBody>
            
            </Card>

            <Card className='card-no-shadow'>

                <CardHeader className='border-top'>
                    <CardTitle className='mb-0'>Update Your Password</CardTitle>
                </CardHeader>

                <CardBody>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <label className='mb-0'>New Password</label>
                                <Input 
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <label className='mb-0'>Confirm New Password</label>
                                <Input 
                                    type="password"
                                    value={passwordConfirm}
                                    onChange={(e) => setPasswordConfirm(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                </CardBody>

                {err ? (
                    <CardBody className='border-top'>
                        <p className='text-sm mb-0 text-warning font-weight-bold'>{err}</p>
                    </CardBody>
                ) : ''}

                <CardFooter className='text-right'>
                        <button disabled={loading} onClick={onUpdatePassword} className='btn btn-success btn-sm'>Update Password</button>
                </CardFooter>

            </Card>

            {hasMultipleCases ? (
                <Card className='card-no-shadow'>
                    <CardHeader>
                        <CardTitle className='mb-0'>Multiple Cases Found</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className='text-sm mb-0'>It looks like your account has multiple cases associated with it. </p>
                        <p className='text-sm mb-0'><Link className=" text-dark" to="/launchpad"><b className='text-underline text-info'>CLICK HERE</b> if you are trying to view another matter you are associated with.</Link></p>
                    </CardBody>
                
                </Card>
            ) : ''}

            <p className='text-right text-dark'>
                <Link to="/logout"><b className='text-info'>CLICK HERE</b> <span className='text-dark'>to logout of your account.</span></Link>
            </p>

        </div>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(Signatures);