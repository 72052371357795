import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';

import { Link, Redirect } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Form, FormGroup, Input, Row } from "reactstrap";

import { connect } from 'react-redux';

import api from 'api'

import { getUrlParameter, setUrlParameter } from "utils/urls";

import moment from 'moment';

import { setViewingUser } from 'store/functions/auth/auth'

import Logo from 'assets/img/Logo.png'

const Login = ({load, onMFANeeded}) => {

	const [redirect, setRedirect] = useState(false);
	const [isReset, setIsReset] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [err, setErr] = useState(null);

	const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') document.getElementById("loginButton").click();
	}, [])


	const onLogin = useCallback(async () => {
		if(!email) return setErr('Invalid email');
		if(!password) return setErr('Invalid Password');

        //validate the users password
		const loggedIn = await api.auth.login({email, password})
        console.log(loggedIn)

        if(loggedIn.success) {
            if(loggedIn.data.mfa_required) return onMFANeeded({
                mfa_fields: loggedIn.data.mfa_fields,
                email, 
                password, 
            })
            await setViewingUser();
            const redirectTo = getUrlParameter('redirect');
            setRedirect(redirectTo ? redirectTo : '/launchpad')

        } else {
            if(loggedIn.message && loggedIn.message[0] && loggedIn.message[0] === 'bulk attempts met') {
                return  `Max login attempts met, please retry after ${moment.unix(parseInt(loggedIn.message[1])).format('MM/DD/YYYY h:mm A')}`;
            }
        
            setErr(loggedIn.message)
        }

	}, [email, password, onMFANeeded])

    const focusInput = useCallback(() => {
		const focus = (timesTried = 0) => {
            if(timesTried > 20) return;
            const input = document.getElementById(`login-email-input`);
            if(input) return input.focus();

            setTimeout(() => {
                timesTried++;
                focus();
            }, 100)
        }
        focus()
	}, [])

	useEffect(() => {
        const reset = getUrlParameter('reset');
        if(reset) {
            setIsReset(true)
            setUrlParameter('reset', '')
        }

        focusInput();
		window.addEventListener('focus', focusInput)
		return () => window.removeEventListener('focus', focusInput)
	}, [focusInput])

    if(redirect) return <Redirect to={redirect} />

	return (
		<div>
			<Helmet>
				<title>Login</title>
				<meta name="description" content="Login" />
			</Helmet>

            <div className="header  py-6 py-lg-5 mb-lg-5 pt-lg-3">
                <Container>
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">

                                <img style={{width: 200}} alt="..."  src={Logo} />
                                <p className="text-lead text-white mt-3">
                                    <b className="font-weight-bold d-block">Account Login</b>
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

			<Container className="mt--9 pb-5">
				<Row className="justify-content-center">
					<Col lg="5" md="7">

						<Form role="form">
						
							<Card className="bg-white border-0 mb-0 z-depth-1" >

								<CardHeader>
									<CardTitle className="mb-0">Enter Credentials Below</CardTitle>
								</CardHeader>

								<CardBody className="">
										<FormGroup className="mb-3" >
                                        <label className="form-control-label">Email</label>
                                        <Input
                                            id="login-email-input"
                                            name="email"
                                            onKeyDown={_handleKeyDown}
                                            placeholder=""
                                            type="email"
                                            autoComplete="email"
                                            value={email	 || ''}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </FormGroup>

                                    <FormGroup className="mt-4">
                                        <label className="form-control-label">Password</label>
                                        <Input
                                            name="password"
                                            onKeyDown={_handleKeyDown}
                                            placeholder=""
                                            type="password"
                                            autoComplete="current-password"
                                            value={password || ''}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </FormGroup>
								</CardBody>

                                {isReset ? (
                                    <CardFooter>
                                        <p className="mb-0"><i className="fas fa-info info-circle text-info mr-2 " /> Your password has been reset!</p>
                                    </CardFooter>
                                ) : null}
                                {err ? (
                                    <CardFooter>
                                        <p className="mb-0 text-danger">{err}</p>
                                    </CardFooter>
                                ) : null}

								<CardFooter className="text-center">
									<Button id="loginButton" onClick={onLogin} className="btn-block" color="success" type="button">
										Sign in
									</Button>
								</CardFooter>

							</Card>

						</Form>


						<Row className="mt-3">

							<Col xs="6" className="text-left">
								<Link to={`/register`} >
									<small className="text-white">Register Account</small>
								</Link>
							</Col>
							<Col xs="6" className="text-right">
								<Link to={`/forgot`} >
									<small className="text-white">Forgot Password?</small>
								</Link>
							</Col>

						</Row>

					</Col>
				</Row>
			</Container>

		</div>
	)
}

const mapStateToProps = state => {
    return {
		load: state.load,
    };
};

export default connect(mapStateToProps, '')(Login);

