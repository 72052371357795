/*
Documentation

this file handles password recovery by sending a hashed security code to a users email
it then verifies the code and lets them update their password

*/

import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { Helmet } from 'react-helmet'

import ConfirmEmail from './ConfirmEmail'
import ConfirmCode from './ConfirmCode'
import UpdatePassword from './UpdatePassword'

import Logo from 'assets/img/Logo.png'

const Forgot = ({load}) => {

    const [view, setView] = useState('ConfirmEmail');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [redirect, setRedirect] = useState('');

    const onConfirmedEmail = useCallback((_email) => {
        setEmail(_email)
        setView('ConfirmCode')
    }, [])

    const onConfirmedCode = useCallback((_code, _questions) => {
        setCode(_code)
        setView('UpdatePassword')
    }, [])

    const onPasswordUpdate = useCallback(() => {
        setRedirect(`/?reset=true`)
    }, [])

    if(redirect) return <Redirect to={redirect} />

    return (
        <div>
            <Helmet>
                <title>Forgot Password</title>
                <meta name="description" content="Forgot Password" />
            </Helmet>

            <div className="header  py-7 py-lg-5 mb-lg-5 pt-lg-3">
            <Container>
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">

                                <img style={{width: 200}} alt="..."  src={Logo} />
                                <p className="text-lead text-white mt-3">
                                    <b className="font-weight-bold d-block">Forgot your password?</b>
                                    We all do it too, just fill out the form below.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

			<Container className="mt--9 pb-5">
				<Row className="justify-content-center">
					<Col lg="5" md="7">

                        {view === 'ConfirmEmail' && (
                            <ConfirmEmail
                                onConfirmedEmail={onConfirmedEmail}
                            />
                        )}

                        {view === 'ConfirmCode' && (
                            <ConfirmCode
                                email={email}
                                onConfirmedCode={onConfirmedCode}
                            />
                        )}

                        {view === 'UpdatePassword' && (
                            <UpdatePassword
                                code={code}
                                email={email}
                                onPasswordUpdate={onPasswordUpdate}
                            />
                        )}

						<Row className="mt-3">

                            <Col xs="12" className="text-center">
                                <Link to="/" >
                                    <p className="text-sm font-weight-bold text-white"><i className="fas fa-arrow-left mr-2" /> Login</p>
								</Link>
							</Col>

						</Row>

					</Col>
				</Row>
			</Container>
        </div>
    )
}

const mapStateToProps = state => {
    return {
		load: state.load,
    };
};

export default connect(mapStateToProps, '')(Forgot);

