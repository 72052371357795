import * as actionTypes from '../../actions';
import store from 'store';

import api from 'api';

export const fetchOverview = async (matterID) => {

    const data = await api.dashboard.overview(matterID)
    if(!data.success) {
        try {
            if(data.message[0].includes('No matter was found')) return window.location.href = '/launchpad'
        } catch(e) {
            console.log(e)
        }
        return store.dispatch({ type: actionTypes.SET_ERR, payload: true, });
    }

    store.dispatch({ type: actionTypes.SET_OVERVIEW, payload: data.data, });    
}

export const setHasSingleCase = async (bool) => {
    store.dispatch({ type: actionTypes.SET_HAS_SINGLE_CASE, payload: bool, });    
}
