import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { Helmet } from 'react-helmet';

import ContactInfo from './ContactInfo'
import HeaderCards from './HeaderCards'
import OutstandingItems from './OutstandingItems'
import RecentActivity from './RecentActivity'
import RecentMessage from './RecentMessage'
import UpcomingEvent from './UpcomingEvent'

const Home = ({ dashboard }) => {
    return (
        <div>

            <Helmet>
                <title>My Dashboard</title>
                <meta name="description" content="My Dashboard" />
            </Helmet>

           <HeaderCards />

            <Row>

                <Col lg={4}>
                    <UpcomingEvent />
                    <RecentActivity />
                </Col>

                <Col lg={8}>

                    <ContactInfo />
                    <OutstandingItems />
                    <RecentMessage />

                </Col>
            </Row>

        </div>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);