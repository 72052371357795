import { useCallback, useState } from 'react';

import Login from './Login';
import MFA from './MFA';

const LoginMaster = (props) => {
    const [view, setView] = useState('Login');
    const [mfaFields, setMFAFields] = useState('Login');

    const onMFANeeded = useCallback((fields) => {
        setMFAFields(fields);
        setView('MFA')
    }, [])

    if(view === 'Login') {
        return <Login {...props} setView={setView} onMFANeeded={onMFANeeded} />
    } else if(view === 'MFA') {
        return <MFA {...props} setView={setView} mfaFields={mfaFields} />
    }
}

export default LoginMaster;