import { connect } from 'react-redux'
import { Card, CardBody, CardFooter } from 'reactstrap'

import moment from 'moment'

const Home = ({ overview }) => {
    return (
        <div>
           <h2>Recent Message</h2>
           {overview.recentPortalMessage ? (
                <Card className='card-no-shadow'>
                    <CardBody>
                        <p className='text-sm mb-0'>{overview.recentPortalMessage.body}</p>
                    </CardBody>
                    <CardFooter>
                        <p className='text-sm mb-0 text-dark font-weight-bold'>
                            {overview.recentPortalMessage.user.display_name}
                            {' on '}
                            {moment.unix(overview.recentPortalMessage.created_at).format('MM/DD/YYYY')} 
                            {' at '}
                            {moment.unix(overview.recentPortalMessage.created_at).format('h:mm A')} 
                        </p>
                    </CardFooter>
                </Card>
           ) : (
                <Card className='card-no-shadow'>
                    <CardBody>
                        <p className='text-sm mb-0'>No Recent Messages Or Notifications Found.</p>
                    </CardBody>
                </Card>
           )}
           
        </div>
    )
}


const mapStateToProps = state => {
	return {
	    overview: state.dashboard.overview,
	};
};

export default connect(mapStateToProps, '')(Home);