import { Row, Col, Input } from 'reactstrap'
import NameCircle from 'components/markup/misc/NameCircle'

import Logo from 'assets/img/Logo.png'

const Sidebar = ({ setSidebarOpen }) => {

    return (
        <div className="archk-header">
            <img src={Logo} alt="..." width={150} />
            <i onClick={() => setSidebarOpen(true)} className="fas fa-bars archk-sidebar-open" />

        </div>
    )
}

export default Sidebar