
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";

import Dashboard from './Dashboard';

const DashboardWrapper = (props) => {
    if(!props.viewing_user) return <Redirect to="/" />
    return <Dashboard {...props} />
}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(DashboardWrapper);