let keys = {};

const HOSTNAME = window.location.hostname

//using production keys
if(HOSTNAME.includes('.intuidesk.com')) {

    keys = {
        ...keys,
        API_URL               : 'https://portal-api.intuidesk.com',
        SYSTEM_API_KEY        : 'architeck-1957163548',
    }

//using staging keys
} else if(HOSTNAME.includes('vercel.app')) {

    keys = {
        ...keys,
        API_URL               : 'https://staging.portal-api.intuidesk.com',
        SYSTEM_API_KEY        : 'architeck-4184167461',
    }

//using development keys
} else {

    keys = {
        ...keys,

        API_URL               : 'http://localhost:5004',
        SYSTEM_API_KEY        : 'architeck-',
    }

}

export default keys
