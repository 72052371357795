import { Row, Col, Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';

import formatText from 'utils/formatText'

const HomeContactInfo = ({ overview, viewing_user }) => {
    return (
        <Card className='card-no-shadow text-center'>
            <CardHeader>
                <CardTitle className='mb-0'>{viewing_user.display_name ? viewing_user.display_name : 'Unknown User'}</CardTitle>
            </CardHeader>
            <CardBody>
                
                <Row>
                    <Col md={6}>
                        <p className='text-sm mb-0'>Email <Link to={`/${overview.matter.id}/dashboard/profile`}> <i className="fas fa-edit ml-2 text-info" /></Link></p>
                        <p className='font-weight-bold mb-0'>
                            {viewing_user.email ? viewing_user.email : <span className='text-warning font-weight-bold'>No Phone # Found</span>} 
                        </p>
                    </Col>
                    <Col md={6}>
                        <p className='text-sm mb-0'>Phone  <Link to={`/${overview.matter.id}/dashboard/profile`}> <i className="fas fa-edit ml-2 text-info" /></Link></p>
                        <p className='font-weight-bold mb-0'>
                            {viewing_user.phone ? formatText(viewing_user.phone).phone() : <span className='text-warning font-weight-bold'>No Email Address Found</span>} 
                        </p>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}


const mapStateToProps = state => {
	return {
	    overview: state.dashboard.overview,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(HomeContactInfo);