import { connect } from 'react-redux'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'

import { Link } from 'react-router-dom'


const Home = ({ DIVISION, overview }) => {
    return (

        <Card className='card-no-shadow'>
            <CardHeader>
                <CardTitle className='mb-0'>Get Support</CardTitle>
            </CardHeader>

            <CardBody>
                <p className='text-sm mb-0'>Here at {DIVISION.display_name} we are committed to helping you every step of the way. While we are quickest at responding to emails call us any time. To view more specific contact information <Link className="text-info font-weight-bold text-underline" to={`/${overview.matter.id}/dashboard/support`}>CLICK HERE.</Link></p>
                {/* <p className='text-sm mb-0'>Here at {DIVISION.display_name} we are committed to helping you every step of the way. While we are quickest at responding to emails call us any time or <Link className="text-underline font-weight-bold" to="/dashboard/support">CLICK HERE to schedule a callback.</Link>. If you feel your case is not being handled up to your standards you can file a complaint directly to our management by <Link className="text-underline font-weight-bold text-warning" to="/dashboard/support/complaint">CLICKING HERE.</Link></p> */}

            </CardBody>
        </Card>
    )
}


const mapStateToProps = state => {
	return {
	    overview: state.dashboard.overview,
	    DIVISION: state.load.DIVISION,
	};
};

export default connect(mapStateToProps, '')(Home);