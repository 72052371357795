import { useCallback } from 'react';
import Logo from 'assets/img/Logo.png'
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import moment from 'moment';

const SidebarLarge = ({ overview, sidebarOpen, setSidebarOpen }) => {
    const location = useLocation();

    const getClassName = useCallback((tab) => {

        const split = location.pathname.split('/')
        const isDashboard = split[2] === 'dashboard' && !split[3]

        if(tab === '/first') {
            if(isDashboard) return 'archk-sidebar-large-active-before'
        } else if(tab === '/dashboard') {
            if(isDashboard) return 'archk-sidebar-large-active'
            if(location.pathname.includes('files')) return 'archk-sidebar-large-active-before'
        } else if(tab === '/files') {
            if(isDashboard) return 'archk-sidebar-large-active-after'
            if(location.pathname.includes('files')) return 'archk-sidebar-large-active'
            if(location.pathname.includes('information')) return 'archk-sidebar-large-active-before'
        } else if(tab === '/information') {
            if(location.pathname.includes('files')) return 'archk-sidebar-large-active-after'
            if(location.pathname.includes('information')) return 'archk-sidebar-large-active'
            if(location.pathname.includes('signatures')) return 'archk-sidebar-large-active-before'
        } else if(tab === '/signatures') {
            if(location.pathname.includes('information')) return 'archk-sidebar-large-active-after'
            if(location.pathname.includes('signatures')) return 'archk-sidebar-large-active'
            if(location.pathname.includes('support')) return 'archk-sidebar-large-active-before'
        } else if(tab === '/support') {
            if(location.pathname.includes('signatures')) return 'archk-sidebar-large-active-after'
            if(location.pathname.includes('support')) return 'archk-sidebar-large-active'
            if(location.pathname.includes('profile')) return 'archk-sidebar-large-active-before'
        } else if(tab === '/profile') {
            if(location.pathname.includes('support')) return 'archk-sidebar-large-active-after'
            if(location.pathname.includes('profile')) return 'archk-sidebar-large-active'
        } else if(tab === '/final') {
            if(location.pathname.includes('profile')) return 'archk-sidebar-large-active-after'
        }

    }, [location])

    return (
        <div className={`archk-sidebar-large ${sidebarOpen ? 'open' : ''}`}>

            {sidebarOpen ?  <div onClick={() => setSidebarOpen(false)} className='archk-sidebar-blackout' />: ''}
            

            <ul>

                <Link to={`/${overview.matter.id}/dashboard`} >
                    <div onClick={() => setSidebarOpen(false)} className='archk-sidebar-logo-wrapper'>
                        <img src={Logo} alt="Fairmax Law" className='w-100' />
                    </div>
                </Link>
               
      
                {/* <li className="">
                    <div className="archk-sidebar-large-item-wrapper">
                    </div>
                </li>  */}

                    <li className={getClassName('/first')}>
                        <div className="archk-sidebar-large-item-wrapper">
                        </div>
                    </li>

                <Link onClick={() => setSidebarOpen(false)}  to={`/${overview.matter.id}/dashboard`}>
                    <li className={getClassName('/dashboard')}>
                        <div className="archk-sidebar-large-item-wrapper">
                            <i className="fas fa-home" />
                            Dashboard
                        </div>
                    </li>
                </Link>
                
                <Link onClick={() => setSidebarOpen(false)}  to={`/${overview.matter.id}/dashboard/files`}>
                    <li className={getClassName('/files')}>
                        <div className="archk-sidebar-large-item-wrapper">
                            <i className="fas fa-file" />
                            Files
                        </div>
                    </li>
                </Link>

                <Link onClick={() => setSidebarOpen(false)}  to={`/${overview.matter.id}/dashboard/information`}>
                    <li className={getClassName('/information')}>
                        <div className="archk-sidebar-large-item-wrapper">
                            <i className="fas fa-search" />
                            Information
                        </div>
                    </li>
                </Link>
                
                <Link onClick={() => setSidebarOpen(false)}  to={`/${overview.matter.id}/dashboard/signatures`}>
                    <li className={getClassName('/signatures')}>
                        <div className="archk-sidebar-large-item-wrapper">
                            <i className="fas fa-pen" />
                            Signatures
                        </div>
                    </li>
                </Link>

                <Link onClick={() => setSidebarOpen(false)}  to={`/${overview.matter.id}/dashboard/support`}>
                    <li className={getClassName('/support')}>
                        <div className="archk-sidebar-large-item-wrapper">
                            <i className="fas fa-comments" />
                            Support
                        </div>
                    </li>
                </Link>
                <Link onClick={() => setSidebarOpen(false)}  to={`/${overview.matter.id}/dashboard/profile`}>
                    <li className={getClassName('/profile')}>
                        <div className="archk-sidebar-large-item-wrapper">
                            <i className="fas fa-user" />
                            Profile
                        </div>
                    </li>
                </Link>

                <li className={getClassName('/final')}>
                    <div className="archk-sidebar-large-item-wrapper">
                    </div>
                </li> 
                {/* <li className="">
                    <div className="archk-sidebar-large-item-wrapper">
                    </div>
                </li>  */}
         

                <li className="">
                    <div className="archk-sidebar-large-item-wrapper py-0">
                        <p className='text-sm text-cente pr-6' style={{lineHeight: '16px'}}>
                        <small>This portal is operated by {overview.division.display_name}. &copy; {moment().format('YYYY')} Intuidesk LLC</small>
                        </p>
                    </div>
                </li> 

            </ul>

        </div>
    )
}


const mapStateToProps = state => {
	return {
	    overview: state.dashboard.overview,
	};
};

export default connect(mapStateToProps, '')(SidebarLarge);