import { Row, Col, Card, CardBody } from 'reactstrap'
import { connect } from 'react-redux'

import { Link } from 'react-router-dom';

import OverallImg from './images/Overall.svg'
import FilesImg from './images/Files.svg'
import SignImg from './images/Sign.svg'
import FormsImg from './images/Forms.svg'

const HomeHeaderCards = ({ overview }) => {

    const total = overview.totalForms + overview.totalDocuments + overview.totalSignatures;
    const completed = overview.completedForms + overview.completedDocuments + overview.completedSignatures;

    const percent = total ? ((completed / total) * 100).toFixed(1) : 100

    return (
        <div>

            <Row>
                
                <Col lg={3} md={6}>
                    <Card className='card-no-shadow bg-gradient-success position-relative' style={{ overflow: 'hidden' }}>
                        <CardBody>
                            <p className='mb-3 text-right text-white'>Overall Progress</p>
                            <h2 className='text-right text-white display-3'>{percent}%</h2>
                            <img src={OverallImg} style={{position: 'absolute', bottom: -5, left: 10, width: 65}} alt="..." />
                        </CardBody>
                    </Card>
                </Col>

                <Col lg={3} md={6}>
                    <Link to={`/${overview.matter.id}/dashboard/files`} >
                        <Card className='card-no-shadow bg-gradient-warning' style={{ overflow: 'hidden' }}>
                            <CardBody>
                                <p className='mb-3 text-right text-white'>Files Approved</p>
                                <h2 className='text-right text-white display-3'>{overview.completedDocuments}/{overview.totalDocuments}</h2>
                                <img src={FilesImg} style={{position: 'absolute', bottom: 15, left: 15, width: 64}} alt="..." />
                            </CardBody>
                        </Card>
                    </Link>
                </Col>
              
                <Col lg={3} md={6}>
                    <Link to={`/${overview.matter.id}/dashboard/information`} >

                        <Card className='card-no-shadow bg-gradient-purple' style={{ overflow: 'hidden' }}>
                            <CardBody>
                                <p className='mb-3 text-right text-white'>Forms Completed</p>
                                <h2 className='text-right text-white display-3'>{overview.completedForms}/{overview.totalForms}</h2>
                                <img src={FormsImg} style={{position: 'absolute', bottom: 15, left: 20, width: 62}} alt="..." />

                            </CardBody>
                        </Card>
                    </Link>
                </Col>

                <Col lg={3} md={6}>
                    <Link to={`/${overview.matter.id}/dashboard/signatures`} >
                        <Card className='card-no-shadow bg-gradient-info' style={{ overflow: 'hidden' }}>
                            <CardBody>
                                <p className='mb-3 text-right text-white'>Docs Signed</p>
                                <h2 className='text-right text-white display-3'>{overview.completedSignatures}/{overview.totalSignatures}</h2>
                                <img src={SignImg} style={{position: 'absolute', bottom: 17, left: 20, width: 40}} alt="..." />
                            </CardBody>
                        </Card>
                    </Link>
                </Col>
               
            </Row>

        </div>
    )
}


const mapStateToProps = state => {
	return {
	    overview: state.dashboard.overview,
	};
};

export default connect(mapStateToProps, '')(HomeHeaderCards);